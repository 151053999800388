import { Link, Outlet } from 'react-router-dom';
import Logo from '../assets/images/logo.svg';
import { Icon } from './Icon';
import { useAuth } from './AuthProvider';
import cn from 'classnames';
import { PopupMenu } from './PopupMenu';
import { Button } from './Button';
import { useWindowSize } from '../hooks/useWindowSize';
import { P, match } from 'ts-pattern';
import { useState } from 'react';
import { LinkGroup } from './LinkGroup';
import { HeaderAccountLinks } from './HeaderAccountLinks';

import styles from './Header.module.scss';

interface HeaderProps {
  isInitializing: boolean;
  isSignedOut: boolean;
  isOpen?: boolean;
}

type MobileHeaderProps = HeaderProps & {
  onVisibilityChange: () => void;
};

function MobileHeader({
  isInitializing,
  isSignedOut,
  isOpen,
  onVisibilityChange,
}: MobileHeaderProps) {
  return (
    <nav>
      <div className={styles.shadow}>
        <div
          className={cn(styles.header, {
            [styles.stage]: import.meta.env.VITE_APP_ENV === 'stage',
          })}
        >
          <Link className={styles.logoLink} to="/">
            <img
              className={styles.logo}
              src={Logo}
              alt="Canadian Starmaker Fund"
            />
          </Link>
          <div className={styles.navigation}>
            {match({ isInitializing, isSignedOut })
              .with(
                { ...P.union({ isInitializing: true }, { isSignedOut: true }) },
                () => <Button size="small" label="Sign In" linkTo="/sign-in" />
              )
              .otherwise(() => null)}
            <Icon
              name={isOpen ? 'x' : 'hamburger'}
              label={isOpen ? 'Close Menu' : 'Menu'}
              className={cn(styles.icon, styles.hamburgerIcon)}
              onClick={onVisibilityChange}
            />
          </div>
        </div>
      </div>
      {isOpen && (
        <div className={styles.open}>
          <HeaderAccountLinks onClick={onVisibilityChange} />
          <LinkGroup
            title="Program Details"
            icon="requirements"
            links={[
              { text: 'Requirements & Rules', href: '/requirements' },
              { text: 'Orion Program', href: '/orion' },
              { text: 'Deadlines', href: '/deadlines' },
            ]}
            onLinkClicked={onVisibilityChange}
          />
          <LinkGroup
            title="About Us"
            icon="star"
            links={[
              { text: 'About Us', href: '/about' },
              { text: 'Board of Directors', href: '/board' },
              { text: 'Financial Statements', href: '/finances' },
            ]}
            onLinkClicked={onVisibilityChange}
          />
          <LinkGroup
            icon="warningCircle"
            links={[{ text: 'FAQs', href: '/faq' }]}
            onLinkClicked={onVisibilityChange}
          />
          <LinkGroup
            icon="phone"
            links={[{ text: 'Contact Us', href: '/contact' }]}
            onLinkClicked={onVisibilityChange}
          />
        </div>
      )}
    </nav>
  );
}

function DesktopHeader({ isInitializing, isSignedOut }: HeaderProps) {
  return (
    <nav
      className={cn(styles.header, {
        [styles.stage]: import.meta.env.VITE_APP_ENV === 'stage',
      })}
    >
      <Link className={styles.logoLink} to="/">
        <img className={styles.logo} src={Logo} alt="Canadian Starmaker Fund" />
      </Link>
      <div className={styles.links}>
        <PopupMenu
          label="Program Details"
          options={[
            {
              label: 'Requirements & Rules',
              href: '/requirements',
            },
            {
              label: 'Orion Program',
              href: '/orion',
            },
            {
              label: 'Deadlines',
              href: '/deadlines',
            },
          ]}
        />
        <PopupMenu
          label="About Us"
          options={[
            { label: 'About Us', href: '/about' },
            {
              label: 'Board of Directors',
              href: '/board',
            },
            {
              label: 'Financial Statements',
              href: '/finances',
            },
          ]}
        />
        <Link className={styles.link} to="/faq">
          FAQs
        </Link>
        <Link className={styles.link} to="/contact">
          Contact Us
        </Link>
      </div>
      {match({
        isInitializing,
        isSignedOut,
      })
        .with(
          { ...P.union({ isInitializing: true }, { isSignedOut: true }) },
          () => <Button size="small" label="Sign In" linkTo="/sign-in" />
        )
        .otherwise(() => (
          <HeaderAccountLinks />
        ))}
    </nav>
  );
}

export function Header() {
  const { isInitializing, isSignedOut } = useAuth();
  const [isOpen, setIsOpen] = useState(false);
  const size = useWindowSize();

  // reset the status if the menu is open and resized to desktop
  if (isOpen && size.screen === 'desktop') {
    setIsOpen(false);
  }

  return (
    <header>
      {match(size.screen)
        .with('desktop', () => (
          <DesktopHeader
            isInitializing={isInitializing}
            isSignedOut={isSignedOut}
          />
        ))
        .otherwise(() => (
          <MobileHeader
            isInitializing={isInitializing}
            isOpen={isOpen}
            isSignedOut={isSignedOut}
            onVisibilityChange={() => setIsOpen(!isOpen)}
          />
        ))}
      {!isOpen && <Outlet />}
    </header>
  );
}
