import { FundingRound } from '../../../schemas/fundingRound/fundingRoundSchema';
import { Button } from '../../Button';
import { Link } from '../../Link';
import { FundingRoundStatusType } from '../../../graphql/operations';
import { RoundDetails } from './RoundDetails';
import { RoundTitle } from './RoundTitle';
import { RoundSubtitle } from './RoundSubtitle';
import { pluralize } from '../../../utils/pluralize';

import styles from './RoundRow.module.scss';

type RoundRowProps = {
  round: FundingRound;
  onEdit: () => void;
  onDelete: () => void;
};

export function RoundRow({ round, onEdit, onDelete }: RoundRowProps) {
  const { title, status, allowOrion, numberOfApplications } = round;

  return (
    <div className={styles.container}>
      <div className={styles.card}>
        <RoundTitle title={title} status={status} />
        <RoundSubtitle status={status} allowOrion={allowOrion} />
      </div>
      <div className={styles.details}>
        <div className={styles.infoContainer}>
          <RoundDetails direction="row" round={round} fontSize="small" />
          <div className={styles.sideButtons}>
            <Button
              label="Edit"
              startIcon="editPencil"
              size="small"
              variant="secondary"
              onClick={onEdit}
            />
            {numberOfApplications === 0 && (
              <Button
                variant="neutral"
                size="small"
                label="Remove"
                onClick={() => {
                  if (
                    window.confirm(
                      'Are you sure you want to remove this funding round?'
                    )
                  ) {
                    onDelete();
                  }
                }}
              />
            )}
          </div>
        </div>
        {status !== FundingRoundStatusType.Upcoming &&
          numberOfApplications > 0 && (
            <Link
              className={styles.applications}
              href={`/admin/search-applications?round=${title}`}
              iconSize="small"
              endIcon="externalLink"
            >
              {`View ${pluralize(numberOfApplications, 'Application')} `}
            </Link>
          )}
        <Button
          length="auto"
          size="small"
          startIcon="users"
          variant="neutral"
          label="Board Meeting Page"
          linkTo={`/admin/board-meeting/${title}`}
          className={styles.button}
        />
      </div>
    </div>
  );
}
